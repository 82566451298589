import { default as index_46htmlpnpK0BYpaoMeta } from "/root/nuxt/city-live-pc/pages/index.html.vue?macro=true";
import { default as _91machineId_93_46htmlJDgeZSJT1LMeta } from "/root/nuxt/city-live-pc/pages/machine/[machineId].html.vue?macro=true";
import { default as _91page_93_46htmldzOZxD6BpQMeta } from "/root/nuxt/city-live-pc/pages/machine/home/[category]/[type]/[area]/[page].html.vue?macro=true";
import { default as machines_46htmlsXj7K0p2a4Meta } from "/root/nuxt/city-live-pc/pages/machines.html.vue?macro=true";
import { default as old_46htmlCs9Kp01iaYMeta } from "/root/nuxt/city-live-pc/pages/old.html.vue?macro=true";
import { default as _91shopId_93_46htmlDjNi3actMbMeta } from "/root/nuxt/city-live-pc/pages/store/[shopId].html.vue?macro=true";
import { default as _91page_93_46html1qiRshDQ0oMeta } from "/root/nuxt/city-live-pc/pages/store/home/[area]/[page].html.vue?macro=true";
export default [
  {
    name: "index.html",
    path: "/index.html",
    component: () => import("/root/nuxt/city-live-pc/pages/index.html.vue")
  },
  {
    name: "machine-machineId.html",
    path: "/machine/:machineId().html",
    component: () => import("/root/nuxt/city-live-pc/pages/machine/[machineId].html.vue")
  },
  {
    name: "machine-home-category-type-area-page.html",
    path: "/machine/home/:category()/:type()/:area()/:page().html",
    component: () => import("/root/nuxt/city-live-pc/pages/machine/home/[category]/[type]/[area]/[page].html.vue")
  },
  {
    name: "machines.html",
    path: "/machines.html",
    component: () => import("/root/nuxt/city-live-pc/pages/machines.html.vue")
  },
  {
    name: "old.html",
    path: "/old.html",
    component: () => import("/root/nuxt/city-live-pc/pages/old.html.vue")
  },
  {
    name: "store-shopId.html",
    path: "/store/:shopId().html",
    component: () => import("/root/nuxt/city-live-pc/pages/store/[shopId].html.vue")
  },
  {
    name: "store-home-area-page.html",
    path: "/store/home/:area()/:page().html",
    component: () => import("/root/nuxt/city-live-pc/pages/store/home/[area]/[page].html.vue")
  }
]